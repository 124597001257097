import React from 'react';
import { Link } from 'gatsby';

import Layout from 'components/layout/Layout';
import {
  CompareDomainsLink,
  CompareFrameworksLink,
  CompareTermsLink
} from 'components/VisualToolLink';

export default class VisualToolsPage extends React.Component {
  render() {
    return (
      <Layout>
        <h1>Compare Across Frameworks</h1>

        <p className="emphasis">
          Use the visual tools below to explore similarities and differences
          between frameworks. Click on a tool to get started.
        </p>

        <p>
          <a href="/frameworks/">View All Frameworks</a>
        </p>

        <p>
          <a href="/frameworks/">Thesaurus</a>
        </p>

        <div className="VisualToolLink-index-wrapper">
          <CompareDomainsLink
            description="Explore the relative emphasis each framework places on six skill areas common to the non-academic domain: cognitive, emotion, social, values, perspectives, and identity. The visualization can be used to understand each framework’s area or range of concentration within the non-academic domain."
            displayStyle="index"
          />
          <CompareFrameworksLink
            description="Quickly compare two frameworks and identify major areas of overlap and points of divergence between them. Use this comparison visualization to see where terms overlap, including where frameworks might target similar skills even when they are called different names. Discover how frameworks are organized in different ways."
            displayStyle="index"
          />
          <CompareTermsLink
            description="This tool can be used to select a single term and identify similar and related terms based on how those terms are defined. For example, terms with different names may have similar definitions. At the same time, terms with the same name may have different definitions. This tool allows you to see how terms may be related and how they are organized within different frameworks."
            displayStyle="index"
          />
        </div>

        <p>
          <Link to="/faq/#visual-tools">Are you adding more visual tools?</Link>
        </p>
      </Layout>
    );
  }
}
